import Joi from 'joi';
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

import withTranslation from "../../hocs/withTranslation";
import { getStyles } from "../../store/selectors";
import {
    initUniqueIdentifierIfNeeded, resetSignup,
    resetSignupKeepData, signup
} from "../../store/user/actions";
import { USER_DOES_NOT_EXIST } from "../../utils/constants";
import { getParams, setParams } from "../../utils/location";
import AppContainer from "../AppContainer";
import BirthdayDatePicker from "../BirthdayDatePicker";
import Button from "../Button";
import ButtonGroup from "../ButtonGroup";
import ErrorMessage from "../ErrorMessage";
import ExternalLink from "../ExternalLink";
import CancelIcon from "../icons/CancelInput.svg";
import GiftCard from "../icons/GiftCard.svg";
import LockIcon from "../icons/LockIcon.svg";
import {
    EmailInput, FullNameInput, InputGroup, PhoneInput, TextInput
} from "../Inputs";
import { LOGIN_TYPES } from "../LoginView";
import PreferredLocations from "../PreferredLocations";
import RichText from "../rich-text";
import * as styles from "./index.module.scss";
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import ErrorXIcon from "../icons/ErrorX.svg";

const PHONE_NUMBER = "phoneNumber";
const NAME = "name";
const EMAIL = "email";
const BIRTHDAY = "birthday";
const PREFERRED_LOCATIONS = "preferredLocations";
const GIFT_CARD_NUMBER = "giftCardNumber";
const GIFT_CARD_PIN = "giftCardPIN";

const FIELDS = [PHONE_NUMBER, NAME, EMAIL, BIRTHDAY];

//TODO it can be moved under PreferredLocations component later
const preferredLocationsSchema = Joi.array()
  .items(Joi.string().required())
  .label(PREFERRED_LOCATIONS)
  .required();

class SignupView extends React.Component {
  fieldsRefs = {};
  state = { birthday: {}, preferredLocations: [], errors: {}, confirmedPrivacy: false };


  componentDidMount() {
    if (this.props?.user?.loggedIn) {
      // Notify parent window if user is already logged in, useful for iframe embedding.
      window?.parent.postMessage("IFRAME:USER-ALREADY-LOGGED-IN", "*");
    }

    if (this.props.user.userId) {
      // TODO: already logged in state, we will fix this when upgrading to React-Hooks
    } else {
      // Notify parent window that the user has navigated to the signup page, useful for iframe embedding.
      window?.parent.postMessage("IFRAME:USER-GOT-INTO-SIGNUP-PAGE", "*");

      const { from } = getParams(this.props.location);
      if (from === "signup") {
        this.props.resetSignupKeepData();
      } else {
        this.props.resetSignup();
      }
    }
  }

  onSubmit = () => {
    const {
      location,
      appStyles: { showBirthdayOnSignup },
      app: { loginType },
      isInStoreSignup,
    } = this.props;

    if (isInStoreSignup) {
      this.props.initUniqueIdentifierIfNeeded({ force: true });
    }

    const fields =
      showBirthdayOnSignup &&
        !isInStoreSignup &&
        loginType !== LOGIN_TYPES.GIFT_CARD
        ? FIELDS
        : _.without(
          loginType === LOGIN_TYPES.GIFT_CARD
            ? _.concat(FIELDS, GIFT_CARD_NUMBER)
            : FIELDS,
          BIRTHDAY,
        );

    Promise.all(_.compact([
      ..._.map(
        fields,
        (field) =>
          new Promise((resolve) =>
            this.fieldsRefs[field].validate((error, value) =>
              resolve({ name: field, value, error })
            )
          )
      ),
      // joi validation for preferredLocations
      _.get(
        this.props.pageContext,
        "businessAppConfiguration.requirePreferredLocationsOnSignup"
      ) && {
        error: null,
        name: PREFERRED_LOCATIONS,
        ...preferredLocationsSchema.validate(this.state.preferredLocations),
      },
    ])).then((results) => {
      if (_.every(results, ["error", null])) {
        const formData = _.reduce(
          results,
          (accFields, { name, value }) => ({ ...accFields, [name]: value }),
          {}
        );

        this.fieldsRefs[PHONE_NUMBER].inputElement.focus();
        this.fieldsRefs[PHONE_NUMBER].inputElement.blur();
        const { signupGroupAlias, signupGroup } = getParams(location);

        if (this.props.googleReCaptchaProps) {
          this.props.googleReCaptchaProps
            .executeRecaptcha("signup")
            .then((recaptchaToken) => {
              if (recaptchaToken) {
                this.props.signup({
                  ...formData,
                  signupGroupAlias: signupGroupAlias || signupGroup,
                  recaptchaToken
                });
              } else {
                this.setState({ validatingForm: false });
              }
            })
            .catch((e) => {
              this.setState({ validatingForm: false });
            });
        } else {
          this.props.signup({
            ...formData,
            signupGroupAlias: signupGroupAlias || signupGroup,
          });
        }

        
      } else {
        console.log("has errors");

        // TODO move this handling validation error for preferred locations to a proper place
        if (_.get(
          this.props.pageContext,
          "businessAppConfiguration.requirePreferredLocationsOnSignup"
        ) &&
          results.find((result) => result && result.name === PREFERRED_LOCATIONS)
            .error
        ) {
          this.setState({
            errors: {
              preferredLocations: "Please select at least one location",
            },
          });
        }
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.user.signupState.sent && this.props.user.signupState.sent) {
      this.props.nextTransition && this.props.nextTransition();
    }
  }

  registerInput = (fieldName) => (ref) => {
    this.fieldsRefs = { ...this.fieldsRefs, [fieldName]: ref };
  };

  clearInputs = () =>
    _.forEach(
      this.fieldsRefs,
      (ref) => ref && ref.clearInput && ref.clearInput(),
    );

  render() {
    const {
      T,
      appStyles,
      pageContext: {
        branches,
        business: {name: businessName, areaCode },
        businessAppConfiguration: {
          allowAnyName,
          minimumAllowedAge,
          requireLoginForOrder,
          requirePreferredLocationsOnSignup,
          askForPreferredLocations,
          recaptchaKey
        },
      },
      user: {
        signupState: { sending, sent, error, name, phoneNumber, email },
      },
      app: { loginType },
      modalMode,
      disableSignupInputs,
      openAuthLogin,
      handleClose,
      isInStoreSignup,
    } = this.props;

    if (error && error !== USER_DOES_NOT_EXIST) {
      console.log("ERROR", this.props);
      console.error(error);
    }

    const { SignupTitle = {}, links = {}, SignupGreeting } = appStyles;
    return (
      <AppContainer.Content
        appStyles={appStyles}
        style={isInStoreSignup ? { paddingTop: 0 } : null}
        modalMode={modalMode}
        animate
      >
        {!isInStoreSignup && (
          <AppContainer.CenteredColumn>
            <h2 className={styles.Title} style={SignupTitle}>
              {loginType !== LOGIN_TYPES.GIFT_CARD
                ? T("Get Started")
                : T("Signup and connect your Gift Cards")}
            </h2>
            <div style={SignupGreeting}>
              <RichText>
                {loginType !== LOGIN_TYPES.GIFT_CARD
                  ? T(
                    "Join our family and use the app to skip the line, feel the love and share the good.",
                  )
                  : T(
                    "In order to use your physical gift card online, please sign up and enter your card number below",
                  )}
              </RichText>
            </div>
            <p style={{ display: "flex", alignItems: "center" }}>
              {T("Already a Member?")}{" "}
              <Button
                onClick={openAuthLogin}
                appStyles={appStyles}
                centered
                slim
                label={T("Login")}
                style={{
                  ...(appStyles.rtl ? { marginRight: 8 } : { marginLeft: 8 }),
                }}
              >
                {T("Login")}
              </Button>
            </p>
            {requireLoginForOrder && (
              <small>{T("* Ordering requires signing up")}</small>
            )}
          </AppContainer.CenteredColumn>
        )}
        <InputGroup appStyles={appStyles} T={T}>
          <FullNameInput
            allowAnyName={allowAnyName}
            refEl={this.registerInput(NAME)}
            initialValue={name}
            placeholder={T("Full Name")}
            disabled={disableSignupInputs}
          />
          <PhoneInput
            refEl={this.registerInput(PHONE_NUMBER)}
            initialValue={phoneNumber}
            placeholder={T("Phone Number")}
            areaCode={areaCode}
            locale={appStyles.locale}
            disabled={disableSignupInputs}
          />
          <EmailInput
            refEl={this.registerInput(EMAIL)}
            initialValue={email}
            notRequired={appStyles.noEmailRequired}
            placeholder={T("Email Address")}
            disabled={disableSignupInputs}
          />
          {appStyles.showBirthdayOnSignup &&
            loginType != LOGIN_TYPES.GIFT_CARD && (
              <BirthdayDatePicker
                T={T}
                appStyles={appStyles}
                ref={this.registerInput(BIRTHDAY, "ref")}
                birthday={this.state.birthday}
                onChange={(birthday) => this.setState({ birthday })}
                minimumAllowedAge={minimumAllowedAge}
                required={Boolean(minimumAllowedAge)}
              />
            )}
          {askForPreferredLocations && (
            <PreferredLocations
              T={T}
              style={{ marginBottom: "16px" }}
              required={requirePreferredLocationsOnSignup}
              appStyles={appStyles}
              availableLocations={branches.filter(
                ({ isDisplayed }) => isDisplayed
              )}
              value={this.state.preferredLocations}
              errorMessage={T(this.state.errors.preferredLocations)}
              onChange={(preferredLocations) =>
                this.setState({ preferredLocations: preferredLocations.filter(Boolean) }, () => {
                  if (!requirePreferredLocationsOnSignup) return;

                  const { error } = preferredLocationsSchema.validate(
                    this.state.preferredLocations
                  );
                  if (error) {
                    this.setState({
                      errors: {
                        preferredLocations: "Please select at least one location",
                      },
                    });
                  } else {
                    this.setState({ errors: { preferredLocations: null } });
                  }
                })
              }
            />
          )}
          {loginType === LOGIN_TYPES.GIFT_CARD && (
            <small
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextInput
                onValid={(value) => {
                  // setNewGiftCard({ ...newGiftCard, cardNumber: value })
                }}
                placeholder={T("Gift Card Number")}
                refEl={this.registerInput(GIFT_CARD_NUMBER)}
                appStyles={appStyles}
                iconComponent={GiftCard}
                validator={(input) => !_.isEmpty(input)}
                errorMessage={T("Please enter a valid gift card number")}
                style={{
                  width: "60%",
                }}
              />
              <TextInput
                onValid={(value) => {
                  // setNewGiftCard({ ...newGiftCard, cardPIN: value })
                }}
                refEl={this.registerInput(GIFT_CARD_PIN)}
                appStyles={appStyles}
                iconComponent={LockIcon}
                placeholder={T("Gift Card PIN")}
                noCheckmark
                style={{
                  width: "35%",
                }}
              />
            </small>
          )}

          {_.get(appStyles, "showCheckboxForTermsOnSignup") && 
            <FormGroup>
            <FormControlLabel 
              control={<Checkbox
              onChange={(e) => this.setState({ confirmedPrivacy: e.target.checked, errors: {confirmCheckbox: e.target.checked ? null : "Please check the confirmation checkbox"} })} />} label={T(`By checking this checkbox you confirm you have read and agree to the Terms of Service and Privacy Policy, and consent to receive messages from ${businessName}. Terms and rates may apply. Text STOP to opt out.`)} />
              {!this.state.confirmedPrivacy && this.state.errors.confirmCheckbox && (
                <span
                  className={styles.Error}
                >
                  <ErrorXIcon className="noselect" />
                  {T(this.state.errors.confirmCheckbox)}
                </span>
              )}
            </FormGroup>

          }
        </InputGroup>

        <ButtonGroup appStyles={appStyles}>
          <Button
            onClick={
              (!this.state.confirmedPrivacy && _.get(appStyles, "showCheckboxForTermsOnSignup")) ? 
              ()=>{ this.setState({
                errors: {
                  confirmCheckbox: "Please check the confirmation checkbox",
                },
              })}
              : this.onSubmit }
            appStyles={appStyles}
            centered
            loading={sending}
            completed={sent}
          >
            {loginType !== LOGIN_TYPES.GIFT_CARD
              ? T("Signup")
              : T("Signup and connect gift card")}
          </Button>
        </ButtonGroup>
        {error && (
          <ErrorMessage appStyles={appStyles}>
            {error ===
              "Deleted user, please contact the restaurant support to enable registration for this phone number"
              ? T(
                "Deleted user, please contact the restaurant support to enable registration for this phone number",
              )
              : T("We had a connection problem. Please try again")}
          </ErrorMessage>
        )}
        <AppContainer.CenteredColumn>
          <small className={styles.SmallMessage}>
            {T("By Clicking Sign up, you agree to our")} <br />
            <ExternalLink
              appStyles={appStyles}
              link={links.tos}
              fallbackLink={setParams("/tos", {
                backPath: isInStoreSignup ? "/store" : "/signup",
              })}
            >
              {T("Terms of Service")}
            </ExternalLink>{" "}
            {T("and")}{" "}
            <ExternalLink
              appStyles={appStyles}
              link={links.privacy}
              fallbackLink={setParams("/privacy-policy", {
                backPath: isInStoreSignup ? "/store" : "/signup",
              })}
            >
              {T("Privacy Policy")}
            </ExternalLink>
          </small>
        </AppContainer.CenteredColumn>
        {_.get(appStyles, "hideRecaptchaBadge") && recaptchaKey && (
          <AppContainer.CenteredColumn>
          <small className={styles.SmallMessage}>
                  This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </small>
        </AppContainer.CenteredColumn>
        )}
        <AppContainer.CenteredColumn>
          {isInStoreSignup ? (
            <span
              style={{ color: appStyles.actionColor }}
              onClick={this.clearInputs}
            >
              <CancelIcon
                style={{
                  color: appStyles.actionColor,
                  ...(appStyles.rtl ? { marginLeft: 6 } : { marginRight: 6 }),
                }}
                className={styles.CancelIcon}
              />
              {T("Changed my mind")}
            </span>
          ) : (
            <span
              onClick={handleClose}
              style={{
                color: appStyles.actionColor,
                textTransform: "uppercase",
              }}
            >
              <small>{T("Skip")}</small>
            </span>
          )}
        </AppContainer.CenteredColumn>
      </AppContainer.Content>
    );
  }
}

const SignupPage = withTranslation((props) => <SignupView {...props} />);

const mapStateToProps = (state, props) => {
  const { user, app, header } = state;

  return {
    user,
    app,
    skipPath: header.onCloseTarget,
    appStyles: getStyles(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    signup: (params) => dispatch(signup(params)),
    resetSignup: () => dispatch(resetSignup()),
    resetSignupKeepData: () => dispatch(resetSignupKeepData()),
    initUniqueIdentifierIfNeeded: ({ force }) =>
      dispatch(initUniqueIdentifierIfNeeded(force)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignupPage);

export const SignupViewWithRecaptcha = withGoogleReCaptcha(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignupPage));
