import _ from "lodash";
import moment from "./moment-timezone-with-data";
import { addDays, addMonths, getDay, isLastDayOfMonth } from "date-fns";
import localeDates from "./localeDates";
import { AVAILABLE_LATER, AVAILABLE_NOW, NOW } from "./constants";
import { getBranchesAvailability, getOrderSelectedServingOption } from "../store/selectors";

export const INDEX_TO_WEEKDAY = {
  0: "SUNDAY",
  1: "MONDAY",
  2: "TUESDAY",
  3: "WEDNESDAY",
  4: "THURSDAY",
  5: "FRIDAY",
  6: "SATURDAY",
};

export const WEEKDAY_TO_INDEX = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

export const isOverNightSession = (timeFrame) =>
  timeFrame.endHour < timeFrame.startHour;

export const getStartTime = (timeFrame, timeZoneStr, date) => {
  return  moment
  .tz(date, timeZoneStr)
  .hours(timeFrame.startHour)
  .minutes(timeFrame.startMinute)
  .seconds(0);
}
 

export const getEndTime = (timeFrame, timeZoneStr, date) =>
  moment
    .tz(date, timeZoneStr)
    .hours(timeFrame.endHour)
    .minutes(timeFrame.endMinute)
    .seconds(0);

const getFirstAvailableDay = (
  start,
  branchClosedDays,
  timeZoneStr,
  afterStart,
) => {
  const WEEKDAYS = _.range(7);
  const partitionedDates = afterStart
    ? _.partition(
      _.difference(WEEKDAYS, branchClosedDays),
      (day) => day <= getDay(start),
    )
    : _.partition(
      _.difference(WEEKDAYS, branchClosedDays),
      (day) => day < getDay(start),
    );
  return _.isEmpty(partitionedDates[1])
    ? moment
      .tz(moment(start), timeZoneStr)
      .add(WEEKDAYS.length - getDay(start) + partitionedDates[0][0], "days")
    : moment
      .tz(moment(start), timeZoneStr)
      .add(partitionedDates[1][0] - getDay(start), "days");
};

export const getFirstAvailableDayAfterToday = (
  start,
  branchClosedDays,
  timeZoneStr,
) => getFirstAvailableDay(start, branchClosedDays, timeZoneStr, true);

const getFutureOrderIntervals = ({
  selectedServingOption,
  branchesAvailability,
}) =>
  _.reduce(
    branchesAvailability,
    (futureOrderIntervals, branchAvailability) => {
      const futureOrderInterval =
        _.get(
          branchAvailability,
          `servingOptionTypeToFutureOrderBuffer.${selectedServingOption.type}`,
        ) || selectedServingOption.futureOrderBuffer;

      const timeZoneStr = _.get(branchAvailability, "branch.timeZoneStr");
      if (!timeZoneStr) {
        console.error(
          `In getFutureOrderIntervals: No timeZoneStr set up for branch ${_.get(
            branchAvailability,
            "branch.name",
          )}`,
        );
      } else {
        const today = moment.tz(timeZoneStr).toDate();

        futureOrderIntervals[branchAvailability.branchId] = futureOrderInterval
          ? {
            before: addDays(today, futureOrderInterval.start),
            after: addDays(today, futureOrderInterval.end),
          }
          : {
            before: today,
            after: addMonths(today, 1),
          };
      }
      return futureOrderIntervals;
    },
    {},
  );

// TODO: filter empty time frames
const getClosedDaysFromTimeFrame = (timeFrames) =>
  _.difference(_.range(7), _.map(timeFrames, (timeFrame) => timeFrame.day));

const getBranchesClosedDays = ({
  branchesAvailability,
  timeFrames: servingOptionsTimeFrames,
  deliveryOptions,
}) => {
  const closedDays = _.reduce(
    branchesAvailability,
    (branchClosedDays, branch) => {
      branchClosedDays[branch.branchId] = [];

      if (branch.detailedOpenHours) {
        const openHours = branch.detailedOpenHours.openHours;

        const branchClosedDaysFromOpenHours = _.map(
          _.filter(openHours, {
            startHour: 0,
            startMinute: 0,
            endHour: 0,
            endMinute: 0,
          }),
          "day",
        );

        branchClosedDays[branch.branchId] = branchClosedDaysFromOpenHours;
      }

      if (servingOptionsTimeFrames) {
        const branchClosedDaysForServingOption = getClosedDaysFromTimeFrame(
          servingOptionsTimeFrames,
        );
        branchClosedDays[branch.branchId] = _.merge(
          branchClosedDays[branch.branchId],
          branchClosedDaysForServingOption,
        );
      }
      if (deliveryOptions) {
        const deliveryOptionForBranch = _.find(deliveryOptions, {
          branchId: branch.branchId,
        });
        if (deliveryOptionForBranch) {
          const branchClosedDaysForDelivery = getClosedDaysFromTimeFrame(
            deliveryOptionForBranch.timeFrames,
          );
          branchClosedDays[branch.branchId] = _.merge(
            branchClosedDays[branch.branchId],
            branchClosedDaysForDelivery,
          );
        }
      }
      branchClosedDays[branch.branchId] = _.uniq(
        branchClosedDays[branch.branchId],
      );
      return branchClosedDays;
    },
    {},
  );
  return closedDays;
};

const isServingOptionTypeToSpecificDelayedPickupTimesClosed = (
  timeString,
  timeZoneStr,
  day,
  today,
) => {
  const [hour, minutes] = timeString.split(":");
  if (hour && minutes) {
    const parsedTime = moment
      .tz(moment(day), timeZoneStr)
      .hours(hour)
      .minutes(minutes)
      .seconds(0);

    return parsedTime.isSameOrBefore(today);
  }
  return false;
};

export const getTimeFrameIntersectWithTimeGiven = (
  timeFrames,
  time,
  timeZoneStr,
) =>
  _.find(timeFrames, (timeFrame) => {
    const startTime = getStartTime(timeFrame, timeZoneStr, time);
    const endTime = getEndTime(timeFrame, timeZoneStr, time);

    return time.isSameOrAfter(startTime) && time.isSameOrBefore(endTime);
  });

export const getTimeFrameAfterTimeGiven = (timeFrames, time, timeZoneStr) =>
  _.minBy(
    _.filter(timeFrames, (timeFrame) => {
      const startTime = getStartTime(timeFrame, timeZoneStr, time);
      const endTime = getEndTime(timeFrame, timeZoneStr, time);

      return time.isBefore(startTime) && time.isBefore(endTime);
    }),
    ["startHour", "startMinute"],
  );

export const getTimeFrameIntersection = (
  openHoursTimeFrame,
  servingOptionTimeFrame,
  timeZoneStr,
) => {
  if (_.isEmpty(servingOptionTimeFrame)) {
    return null;
  }
  const timeFrame = _.cloneDeep(openHoursTimeFrame);
  const openHoursStartTime = getStartTime(openHoursTimeFrame, timeZoneStr);
  const openHoursEndTime = getEndTime(openHoursTimeFrame, timeZoneStr);
  const servingOptionStartTime = getStartTime(
    servingOptionTimeFrame,
    timeZoneStr,
  );
  const servingOptionEndTime = getEndTime(servingOptionTimeFrame, timeZoneStr);

  if (openHoursStartTime.isBefore(servingOptionStartTime)) {
    timeFrame.startHour = servingOptionTimeFrame.startHour;
    timeFrame.startMinute = servingOptionTimeFrame.startMinute;
  }
  if (openHoursEndTime.isAfter(servingOptionEndTime)) {
    timeFrame.endHour = servingOptionTimeFrame.endHour;
    timeFrame.endMinute = servingOptionTimeFrame.endMinute;
  }
  return timeFrame;
};

const getBranchesFirstAvailableDate = ({
  branchesAvailability,
  deliveryOptions,
  futureOrderIntervals: futureOrderIntervalsByLocations,
  branchesClosedDays: closedDaysByLocations,
  selectedServingOption,
}) =>
  _.reduce(
    branchesAvailability,
    (firstAvailableDatesByLocations, branchAvailability) => {
      const timeZoneStr = _.get(branchAvailability, "branch.timeZoneStr");
      const branchId = branchAvailability.branchId;

      if (!timeZoneStr) {
        console.error(
          `In getFirstAvailableDateByLocation: No timeZoneStr set up for branch ${_.get(
            branchAvailability,
            "branch.name",
          )}`,
        );
      } else {
        const now = moment.tz(timeZoneStr);

        const firstAvailableDate = _.get(
          branchAvailability,
          "branch.disableFutureOrders",
        )
          ? moment(_.get(
            branchAvailability,"availableFrom"))
          : getFirstAvailableDay(
            futureOrderIntervalsByLocations[branchId].before,
            closedDaysByLocations[branchId],
            timeZoneStr,
          );
        // case serving option is closed for the day or open later today
        if (firstAvailableDate.dayOfYear() === now.dayOfYear()) {
          const deliveryOptionForBranch = _.find(deliveryOptions, { branchId });

          const servingOptionTimeFrames = _.filter(
            selectedServingOption.needsAddress
              ? deliveryOptionForBranch
                ? deliveryOptionForBranch.timeFrames
                : []
              : selectedServingOption.timeFrames,
            { day: firstAvailableDate.day() },
          );

          const openHoursTimeFrames = _.filter(
            _.get(branchAvailability, "detailedOpenHours.openHours"),
            { day: firstAvailableDate.day() },
          );
          const openHoursTimeFrameIntersectedWithNow = getTimeFrameIntersectWithTimeGiven(
            openHoursTimeFrames,
            now,
            timeZoneStr,
          );

          if (_.isEmpty(openHoursTimeFrameIntersectedWithNow)) {
            const hasOpenHourTimeFrameLaterToday = getTimeFrameAfterTimeGiven(
              openHoursTimeFrames,
              now,
              timeZoneStr,
            );
            if (hasOpenHourTimeFrameLaterToday) {
              firstAvailableDatesByLocations[
                branchId
              ] = firstAvailableDate.toDate();
            } else {
              firstAvailableDatesByLocations[
                branchId
              ] = getFirstAvailableDayAfterToday(
                firstAvailableDate.toDate(),
                closedDaysByLocations[branchId],
                timeZoneStr,
              ).toDate();
            }
            return firstAvailableDatesByLocations;
          }

          if (_.isEmpty(servingOptionTimeFrames)) {
            firstAvailableDatesByLocations[
              branchId
            ] = firstAvailableDate.toDate();
            return firstAvailableDatesByLocations;
          }

          const servingOptionTimeFrameIntersectedWithNow = getTimeFrameIntersectWithTimeGiven(
            servingOptionTimeFrames,
            now,
            timeZoneStr,
          );

          if (_.isEmpty(servingOptionTimeFrameIntersectedWithNow)) {
            const hasServingOptionTimeFrameLaterToday = getTimeFrameAfterTimeGiven(
              servingOptionTimeFrames,
              now,
              timeZoneStr,
            );
            if (hasServingOptionTimeFrameLaterToday) {
              firstAvailableDatesByLocations[
                branchId
              ] = firstAvailableDate.toDate();
            } else {
              const timeFrame = getTimeFrameIntersection(
                openHoursTimeFrameIntersectedWithNow,
                servingOptionTimeFrameIntersectedWithNow,
              );
              if (
                !timeFrame ||
                moment(firstAvailableDate)
                  .hours(timeFrame.endHour)
                  .minutes(timeFrame.endMinute)
                  .seconds(0)
                  .isBefore(now)
              ) {
                firstAvailableDatesByLocations[
                  branchId
                ] = getFirstAvailableDayAfterToday(
                  firstAvailableDate.toDate(),
                  closedDaysByLocations[branchId],
                  timeZoneStr,
                ).toDate();
                return firstAvailableDatesByLocations;
              }
            }
          }
        }
        firstAvailableDatesByLocations[branchId] = firstAvailableDate.toDate();
      }
      return firstAvailableDatesByLocations;
    },
    {},
  );

const getAllTimeFramesForDate = (timeFrames, date, timeZoneStr) => {
  const now = moment.tz(timeZoneStr);
  const timeFramesForDay = _.filter(timeFrames, { day: date.day() });
  const overnightSessionFromPreviousDay = _.find(timeFrames, {
    isOverNightSession: true,
    day: date.day()
  });
  if (overnightSessionFromPreviousDay) {
    const overnightStartTime = getStartTime(
      overnightSessionFromPreviousDay,
      timeZoneStr,
      date
    );
    const overnightEndTime = getEndTime(
      overnightSessionFromPreviousDay,
      timeZoneStr,
      date
    );
    const isNowInPreviousDayOvernightSession =
      now.isSameOrAfter(overnightStartTime) &&
      now.isSameOrBefore(overnightEndTime);
    if (isNowInPreviousDayOvernightSession) {
      return [overnightSessionFromPreviousDay];
    }
  }
  const timeFramesForDayWithoutOvernight = _.filter(
    timeFrames,
    (timeFrame) =>
      timeFrame && timeFrame.day === date.day() && !timeFrame.isOverNightSession,
  );
  if (
    _.find(
      timeFramesForDayWithoutOvernight,
      (timeFrame) => timeFrame.hasOvernightSession,
    )
  ) {
    const overnightSessionTimeFrames = _.filter(timeFrames, (timeFrame) => {
      return (
        timeFrame && timeFrame.day === date.day() && timeFrame.isOverNightSession
      );
    });
    return _.concat(
      overnightSessionTimeFrames,
      timeFramesForDayWithoutOvernight
    );
  }
  return timeFramesForDayWithoutOvernight;
};

const filterServingOptionTimeSlots = ({
    timeZoneStr,
    servingTimeData 
}) => (timeSlots) => {
  const {servingOptionTimeFrames} = servingTimeData;
  if (!servingOptionTimeFrames){
    return timeSlots;
  }
  return _.filter(timeSlots, timeSlot => {
    return !_.isEmpty(_.compact(_.map(servingOptionTimeFrames, (servingOptionTimeFrame) => {
      const servingOptionStartTime = getStartTime(
        servingOptionTimeFrame,
        timeZoneStr,
        _.get(servingTimeData, "day") 
      );
      const servingOptionEndTime = getEndTime(
        servingOptionTimeFrame,
        timeZoneStr,
        _.get(servingTimeData, "day") 
      );
      if (
        timeSlot.isSameOrAfter(servingOptionStartTime) &&
        timeSlot.isSameOrBefore(servingOptionEndTime)
      ) {
        return true;
      } 
      return false;
    })
  ));
  });
}

const filterDeliveryAreaTimeSlots = ({
  timeZoneStr,
  servingTimeData,
  selectedServingOption }) => (timeSlots) => {
    const {deliveryAreaTimeFrames } = servingTimeData;
    if (!_.get(selectedServingOption, "needsAddress")){
      return timeSlots;
    }
    return _.filter(timeSlots, timeSlot => 
      _.map(deliveryAreaTimeFrames, (servingOptionTimeFrame) => {
        const servingOptionStartTime = getStartTime(
          servingOptionTimeFrame,
          timeZoneStr,
          _.get(servingTimeData, "day")
        );
        const servingOptionEndTime = getEndTime(
          servingOptionTimeFrame,
          timeZoneStr,
          _.get(servingTimeData, "day") 
        );
        if (
          timeSlot.isSameOrAfter(servingOptionStartTime) &&
          timeSlot.isSameOrBefore(servingOptionEndTime)
        ) {
          return true;
        } 
        return false;
      })
    );
  return true;
}

const getTimeFrameIntervals = ({
  day,
  timeZoneStr,
  openHoursTimeFrames,
}) => {
  const openHourTimeFramesForDate = getAllTimeFramesForDate(
    openHoursTimeFrames,
    day,
    timeZoneStr,
  );
  if (_.isEmpty(openHourTimeFramesForDate)) {
    return [];
  }
  return openHourTimeFramesForDate;
};

export const getFutureOrderAvailability = ({
  selectedServingOption,
  branchesAvailability,
  branchId = null,
  deliveryOptions,
}) => {
  if (
    !selectedServingOption.enableFutureOrders ||
    _.isEmpty(branchesAvailability)
  ) {
    return {
      firstAvailableDates: _.reduce(
        branchesAvailability,
        (firstAvailableDates, branchAvailability) => {
          const timeZoneStr = _.get(branchAvailability, "branch.timeZoneStr");
          if (!timeZoneStr) {
            console.error(
              `In getFutureOrderAvailability: No timeZoneStr set up for branch ${_.get(
                branchAvailability,
                "branch.name",
              )}`,
            );
          } else {
            firstAvailableDates[branchAvailability.branchId] = moment
              .tz(timeZoneStr)
              .toDate();
          }
          return firstAvailableDates;
        },
        {},
      ),
      futureOrderIntervals: {},
      branchesClosedDays: _.reduce(
        branchesAvailability,
        (acc, branchAvailability) => {
          acc[branchAvailability.branchId] = _.map(
            _.filter(branchAvailability.detailedOpenHours.openHours, {
              startHour: 0,
              startMinute: 0,
              endHour: 0,
              endMinute: 0,
            }),
            "day",
          );
          return acc;
        },
        {},
      ),
    };
  }

  const futureOrderIntervals = getFutureOrderIntervals({
    selectedServingOption,
    branchesAvailability,
  });

  const branchesClosedDays = getBranchesClosedDays({
    branchesAvailability,
    ...(selectedServingOption.needsAddress
      ? { deliveryOptions }
      : { timeFrames: selectedServingOption.timeFrames }),
  });

  const firstAvailableDates = getBranchesFirstAvailableDate({
    branchesAvailability,
    deliveryOptions,
    futureOrderIntervals,
    branchesClosedDays,
    selectedServingOption,
  });

  if (branchId) {
    return {
      futureOrderInterval: futureOrderIntervals[branchId],
      branchClosedDays: branchesClosedDays[branchId],
      firstAvailableDate: firstAvailableDates[branchId],
    };
  }
  return { futureOrderIntervals, branchesClosedDays, firstAvailableDates };
};

const getHour = (hour) =>
  _.map(_.split(hour, ":"), (time) => _.toInteger(time));

const isThrottledTime = (time, branchAvailability) => {
  //check if in filtered throttling times:
  const throttlingFilteredTimeFrames = _.get(
    branchAvailability, "throttlingFilteredTimeFrames");
  if (throttlingFilteredTimeFrames) {
    const filteredTimes = _.filter(throttlingFilteredTimeFrames, filteredTimeFrame => {
      return _.get(filteredTimeFrame, "fromDate") &&
        _.get(filteredTimeFrame, "toDate") &&
        time.isSameOrAfter(moment(_.get(filteredTimeFrame, "fromDate")).subtract(1, 'minutes')) &&
        time.isSameOrBefore(moment(_.get(filteredTimeFrame, "toDate")).subtract(1, 'minutes'));
    });
    if (!_.isEmpty(filteredTimes)){
      return true;
    }
  }
  return false;
}

const getServingTimeData = (state, props) => {
  const {
    locations,
    order: { branchId, futureServingTime },
    order,
  } = state;

  const {
    pageContext: {
      branches,
      business: {
        appStyles: { firstAvailableTimePlusPrepTimeInOrderView },
      },
    },
  } = props;

  const branchAvailability = _.get(
    getBranchesAvailability(state, props),
    branchId
  );

  const { timeZoneStr } = _.find(branches, { id: branchId });

  const selectedServingOption = getOrderSelectedServingOption(state, props);

  const deliveryOptionForBranch = _.find(
    locations.deliveryOptions,
    (branch) => branch.branchId === branchAvailability.branchId
  );

  const servingOptionTimeFrames = selectedServingOption.timeFrames;

  const deliveryAreaTimeFrames = (selectedServingOption.needsAddress
  && deliveryOptionForBranch)
    ? deliveryOptionForBranch.timeFrames : undefined;

  const servingDelay =
    selectedServingOption.needsAddress && deliveryOptionForBranch
      ? deliveryOptionForBranch.deliveryTimeDuration
      : branchAvailability.delayedOrderInterval;

  const prepTime =
    _.get(order, "prepTime") ||
    _.get(
      branchAvailability,
      `servingOptionTypeToPrepTime.${selectedServingOption.type}`
    ) ||
    selectedServingOption.prepTime ||
    30;

  const day = moment.tz(moment(futureServingTime || new Date()), timeZoneStr);

  const orderForToday = day.dayOfYear() === moment.tz(timeZoneStr).dayOfYear();

  const overallServingTime =
    firstAvailableTimePlusPrepTimeInOrderView &&
    selectedServingOption.needsAddress
      ? prepTime + servingDelay
      : firstAvailableTimePlusPrepTimeInOrderView || 
          (orderForToday && _.get(branchAvailability, "availability") === AVAILABLE_NOW)
      ? (selectedServingOption.needsAddress ? prepTime + servingDelay : prepTime)
      : 0;

  return {
    overallServingTime,
    servingOptionTimeFrames,
    day,
    orderForToday,
    deliveryAreaTimeFrames
  };
};

const formatToLocaleTime = ({ props }) => (timeSlots) => {
  const {
    pageContext: {
      business: {
        appStyles: { locale = "en-US" },
      },
    },
  } = props;

  return _.map(timeSlots, (timeSlot) =>
    timeSlot.format(localeDates[locale].hourFormat)
  );
};

const filterLocationHoursAvailabilitySlots = ({
  timeZoneStr,
  branchAvailability,
  servingTimeData 
}) => (timeSlots) => {

  return _.filter(timeSlots, timeSlot => {
    return !_.isEmpty(_.compact(_.map(_.get(
      branchAvailability,
      `detailedOpenHours.openHours`
    ), (locationTimeFrame) => {
      if (_.get(locationTimeFrame, "day") != _.get(servingTimeData, "day").day()){
        return false;
      } 

      const locationStartTime = getStartTime(
        locationTimeFrame,
        timeZoneStr,
        _.get(servingTimeData, "day") 
      );
      const locationEndTime = getEndTime(
        locationTimeFrame,
        timeZoneStr,
        _.get(servingTimeData, "day") 
      );
      if (
        timeSlot.isSameOrAfter(locationStartTime) &&
        timeSlot.isSameOrBefore(locationEndTime)
      ) {
        return true;
      } 
      return false;
    })
  ));
  });
}

const isWithinDates = ({timeSlot, orderScheduling, timeZoneStr}) => {
  const fromDate = _.get(orderScheduling, "scheduleTiming.fromDate");
  const toDate = _.get(orderScheduling, "scheduleTiming.toDate");
  if (!toDate && !fromDate){
    return true;
  }

  if (!toDate){
    return (timeSlot.isSameOrAfter(moment.tz(fromDate, timeZoneStr)));
  }

  if (!fromDate){
    return (timeSlot.isSameOrBefore(moment.tz(toDate, timeZoneStr)));
  }

  if (
    timeSlot.isSameOrAfter(moment.tz(fromDate, timeZoneStr)) &&
    timeSlot.isSameOrBefore(moment.tz(toDate, timeZoneStr))
  ) {
    return true;
  }
  return false;
}

export const isTimeSlotInOrderSchedulingTimeframe = ({timeZoneStr, orderScheduling, timeSlot}) => {
  const hours = _.get(orderScheduling, "scheduleTiming.hours");
  if (
    isWithinDates({timeSlot, orderScheduling, timeZoneStr})
  ) {
    if (_.isEmpty(hours)){
      return true;
    }
    const openInTimeFrameHour = _.filter(hours, openHour => {
      if (_.get(openHour, "day") != timeSlot.day()){
        return false;
      }
      const [startHour, startMinute] = _.split(_.get(openHour, "openHour"), ":");
      const [endHour, endMinute] = _.split(_.get(openHour, "closeHour"), ":");
      
      const start = moment.tz(moment(timeSlot), timeZoneStr)
        .hours(startHour)
        .minutes(startMinute)
        .seconds(0);

        const end = moment.tz(moment(timeSlot), timeZoneStr)
        .hours(endHour)
        .minutes(endMinute)
        .seconds(0);

        if (
          timeSlot.isSameOrAfter(start) &&
          timeSlot.isSameOrBefore(end)
        ) {
          return true;
        }
    });
    return !_.isEmpty(openInTimeFrameHour);
  }
  return false;
}

const filterByOrderSchedulings = ({timeZoneStr,
  branchAvailability,
  servingTimeData,
  selectedServingOption
}) => (timeSlots) => {
  if (!_.get(branchAvailability, "orderSchedulings")){
    return timeSlots;
  }
  
  //check if the scheduling has the relevant serving option
  const orderSchedulings = _.get(branchAvailability, "orderSchedulings");
  const relevantSchedulingsForServingOption = _.filter(orderSchedulings, scheduling => {
    return _.isEmpty(_.get(scheduling, "servingOptionTypes")) ||
        _.includes(_.get(scheduling, "servingOptionTypes"), selectedServingOption.type);
  });

  return _.filter(timeSlots, timeSlot => {

    const relevantSchedulingsForTimeslot = _.filter(relevantSchedulingsForServingOption, scheduling => {
      if (_.get(scheduling, "schedulingType") !== "CLOSING"){
        return false;
      }
      if (isTimeSlotInOrderSchedulingTimeframe({timeZoneStr, orderScheduling: scheduling, timeSlot})){
        return true;
      }
      return false;
    });

    return _.isEmpty(relevantSchedulingsForTimeslot);
  });  
}

const filterByPrepTime = ({timeZoneStr, branchAvailability, servingTimeData: {overallServingTime, orderForToday} }) => (timeSlots) => {
  const afterNow = _.filter(timeSlots, timeSlot => timeSlot.isAfter(moment()));

  const firstTimeOption =
    orderForToday && branchAvailability.availability === AVAILABLE_NOW
      ? moment.tz(timeZoneStr).add(overallServingTime, "minutes") // now + prep time
      : _.first(afterNow) ? _.first(afterNow).add(overallServingTime, "minutes") : undefined; // open + prep time


  return _.filter(timeSlots, timeSlot => timeSlot.isSameOrAfter(firstTimeOption));
}

const overrideOpeningScheduling = ({timeZoneStr, branchAvailability, servingTimeData, selectedServingOption}) => (timeSlots) => { 
  //check if the scheduling needs to add more time slots
  if (!_.get(branchAvailability, "orderSchedulings")){
    return timeSlots;
  }

  const relevantSchedulingsForServingOption = _.filter(_.get(branchAvailability, "orderSchedulings"), scheduling => {
    return _.get(scheduling, "schedulingType") === "OPENING" && (_.isEmpty(_.get(scheduling, "servingOptionTypes")) ||
        _.includes(_.get(scheduling, "servingOptionTypes"), selectedServingOption.type));
  });
  
  //check if the date is in this opening time
  const relevantSchedulingsForTimeslot = _.filter(relevantSchedulingsForServingOption, scheduling => {
    return (isWithinDates({timeSlot:_.get(servingTimeData, "day"), orderScheduling: scheduling, timeZoneStr}));
  });

  if (_.isEmpty(relevantSchedulingsForTimeslot)){
    return timeSlots;
  }

  const openHoursTimeFrames = _.flatten(_.map(
    relevantSchedulingsForTimeslot,
    scheduling => {
      return _.get(scheduling, "scheduleTiming.hours")
    }
  ));

  const timeFrameIntervals =_.sortBy(getTimeFrameIntervals({
    day: _.get(servingTimeData, "day"),
    timeZoneStr,
    openHoursTimeFrames
  })
  , (i1) => {
    return i1.startHour * 60 + i1.startMinute + ((moment().day == i1.day) ? 0 : 24*60);
  })

  if (!timeFrameIntervals){
    return [];
  }
  
  const ranges = _.map(timeFrameIntervals, 
    interval => {
      const [startHour, startMinute] = _.split(_.get(interval, "openHour"), ":");
      const [endHour, endMinute] = _.split(_.get(interval, "closeHour"), ":");
      const futureServingTime = _.get(servingTimeData, "day");

      const start = moment.tz(moment(futureServingTime || new Date()) ,timeZoneStr)
      .hours(startHour)
      .minutes(startMinute)
      .seconds(0);
  
      const end = moment.tz(moment(futureServingTime || new Date()), timeZoneStr)
      .hours(endHour)
      .minutes(endMinute)
      .seconds(0);
    
      return moment.range(start, end);
    });


  const afterNow = _.filter(ranges, range => range.end.isAfter(moment.tz(new Date(), timeZoneStr)));
  return _.filter(_.flatten(_.map(afterNow, range => {
      return Array.from(range.by("minute", { step: branchAvailability.delayedOrderInterval || 30 }))
    }
  )), time => time.isAfter(moment.tz(new Date(), timeZoneStr)));
}

const getCurrentAvailabilityStatus = ({timeZoneStr, branchAvailability, servingTimeData, selectedServingOption}) => {
  //check if the scheduling needs to add more time slots
  if (!_.get(branchAvailability, "orderSchedulings")){
    return {status: branchAvailability.availability, error: null};
  }

  const relevantSchedulingsForServingOption = _.filter(_.get(branchAvailability, "orderSchedulings"), scheduling => {
    return _.get(scheduling, "schedulingType") === "OPENING" && (_.isEmpty(_.get(scheduling, "servingOptionTypes")) ||
        _.includes(_.get(scheduling, "servingOptionTypes"), selectedServingOption.type));
  });

  //check if the date is in this opening time
  const relevantSchedulingsForDates = _.filter(relevantSchedulingsForServingOption, scheduling => {
    return (isWithinDates({timeSlot: moment(), orderScheduling: scheduling, timeZoneStr}));
  });

  if (_.isEmpty(relevantSchedulingsForDates)){
    return {status: branchAvailability.availability, error: null};
  }

  const relevantSchedulingsForTimeslot = _.filter(relevantSchedulingsForDates, scheduling => {
    if (isTimeSlotInOrderSchedulingTimeframe({timeZoneStr, orderScheduling: scheduling, timeSlot: moment()})){
      return true;
    }
    return false;
  });

  if (_.isEmpty(relevantSchedulingsForTimeslot)){
    return {status: AVAILABLE_LATER, error: null};
  }else{
    return {status: AVAILABLE_NOW, error:_.first(relevantSchedulingsForTimeslot).message};
  }
}

const filterUnavailableTimeSlots = ({
  firstTimeOption,
}) => (timeSlots) =>
{
  return _.filter(
    timeSlots,
    (timeSlot) =>
      timeSlot && firstTimeOption &&
      timeSlot.isSameOrAfter(firstTimeOption)
  );
}

const filterThrottledTime = ({ branchAvailability }) => (timeSlots) =>
  _.filter(
    timeSlots,
    (timeSlot) => !isThrottledTime(timeSlot, branchAvailability)
  );

const addFirstTimeOptionIfNeeded = ({
  firstTimeOption,
  branchAvailability,
  servingTimeData: { orderForToday },
}) => (timeSlots) => {
  // skip if firstTimeOption is before first available time slot
  if (!firstTimeOption || firstTimeOption.isSameOrBefore(timeSlots[0])) {
    return timeSlots;
  }

  // if order for later today or other day need to add option open time + prep time
  if (
    timeSlots &&
    timeSlots[0] &&
    !timeSlots[0].isSame(firstTimeOption) &&
    (!orderForToday ||
      (branchAvailability.availability === AVAILABLE_LATER && orderForToday))
  ) {
    return [firstTimeOption, ...timeSlots];
  }

  return timeSlots;
};

const addNowTimeSlot = ({
  branchAvailability,
  servingTimeData: { orderForToday },
  selectedServingOption: { disableServingNow },
  props
}) => (timeSlots) =>
  _.concat(
    branchAvailability.availability === AVAILABLE_NOW &&
      !disableServingNow && !isThrottledTime(moment(), branchAvailability) &&
      (((!_.isEmpty(timeSlots) || !props?.appStyles?.firstAvailableTimePlusPrepTimeInOrderView) && orderForToday && !disableServingNow))
      ? [NOW]
      : [],
    timeSlots ? timeSlots : []
  );

const print = ({
    firstTimeOption,
    branchAvailability,
    servingTimeData: { orderForToday },
  }, num) => (timeSlots) => {
    console.log(num, "timeSlots", timeSlots);
    return timeSlots;
  };

export const getPickupTimes = (state, props) => {
  const {
    order: { branchId, futureServingTime },
  } = state;

  const {
    pageContext: { branches },
  } = props;

  const branchAvailability = _.get(
    getBranchesAvailability(state, props),
    branchId
  );

  const selectedServingOption = getOrderSelectedServingOption(state, props);
  const { timeZoneStr } = _.find(branches, { id: branchId });

  const {
    servingOptionTimeFrames,
    deliveryAreaTimeFrames,
    overallServingTime,
    day,
    orderForToday,
  } = getServingTimeData(state, props);


  const availabilityStatus = getCurrentAvailabilityStatus({timeZoneStr, branchAvailability, servingTimeData: {day}, selectedServingOption });
  const initialPickupTimeData = {
    loading: false,
    error: availabilityStatus.error,
    status: availabilityStatus.status,
    firstPickupTimeDelay: overallServingTime,
  };

  // get time frame data based on serving option and location 
  const timeFrameIntervals =_.sortBy(getTimeFrameIntervals({
    day,
    timeZoneStr,
    openHoursTimeFrames: _.get(
      branchAvailability,
      `detailedOpenHours.openHours`
    ),
    servingOptionTimeFrames,
  })
  , (i1) => {
    return i1.startHour * 60 + i1.startMinute + ((moment().day == i1.day) ? 0 : 24*60);
  })

  if (!timeFrameIntervals){
    return initialPickupTimeData;
  }

  const ranges = _.map(timeFrameIntervals, 
    interval => {
      const start = moment.tz(moment(futureServingTime || new Date()), timeZoneStr)
      .day(interval.day)
      .hours(interval.startHour)
      .minutes(interval.startMinute)
      .seconds(0);
  
      const end = moment.tz(moment(futureServingTime || new Date()), timeZoneStr)
      .day(interval.day)
      .hours(interval.endHour)
      .minutes(interval.endMinute)
      .seconds(0);
    
      return moment.range(start, end);
    }
    );

  // if current time is out of time options for today or no time slots available for today 
  if (
    branchAvailability.availability === AVAILABLE_LATER &&
    orderForToday &&
    moment.tz(timeZoneStr).isAfter(_.last(ranges).end)
  ) {
    return { ...initialPickupTimeData, data: [] };
  }

  const afterNow = _.filter(ranges, range => range.start.isAfter(moment()) || range.end.isAfter(moment()));
  console.log(branchAvailability.availability, orderForToday, afterNow);
  const firstTimeOption =
    orderForToday && branchAvailability.availability === AVAILABLE_NOW
      ? moment.tz(timeZoneStr) 
      : _.first(afterNow) ? _.first(afterNow).start.isBefore(moment()) ? moment.tz(timeZoneStr) : _.first(afterNow).start : undefined;


  //init time slots data based on specific serving option or delayedOrderInterval
  const pickupTimesData = _.get(
    branchAvailability,
    `servingOptionTypeToSpecificDelayedPickupTimes.${
      selectedServingOption.type
    }`
  )
    ? _.map(
        _.split(
          _.get(
            branchAvailability,
            `servingOptionTypeToSpecificDelayedPickupTimes.${
              selectedServingOption.type
            }`
          ),
          ","
        ),
        (timeString) => {
          const [hour, minutes] = timeString.split(":");
          if (!(hour && minutes)) {
            return;
          }

          return moment
            .tz(day, timeZoneStr)
            .hours(hour)
            .minutes(minutes)
            .seconds(0);
        }
      )
    :_.flatten(_.map(ranges, range => 
        Array.from(range.by("minute", { step: branchAvailability.delayedOrderInterval }))
      ));

  const options = {
    state,
    props,
    branchAvailability,
    firstTimeOption,
    timeZoneStr,
    servingTimeData: {
      servingOptionTimeFrames,
      deliveryAreaTimeFrames,
      overallServingTime,
      day,
      orderForToday,
    },
    selectedServingOption,
  };

  

  const timeSlotsProcessor = _.flow([
    filterLocationHoursAvailabilitySlots(options),
    filterUnavailableTimeSlots(options),
    filterServingOptionTimeSlots(options),
    filterDeliveryAreaTimeSlots(options),
    filterThrottledTime(options),
    // addFirstTimeOptionIfNeeded(options),
    filterByOrderSchedulings(options),
    overrideOpeningScheduling(options),
    filterByPrepTime(options),
    formatToLocaleTime(options),
    addNowTimeSlot(options),
  ]);

  return {
    ...initialPickupTimeData,
    data: timeSlotsProcessor(pickupTimesData),
  };
};

const isTimeFrameMatch = (
  openHourTimeFrame,
  servingOptionTimeFrame,
  date,
  timeZoneStr,
) => {
  const openHoursStartTime = getStartTime(openHourTimeFrame, timeZoneStr, date);
  const openHoursEndTime = getEndTime(openHourTimeFrame, timeZoneStr, date);
  if (openHoursEndTime && openHoursEndTime.isBefore(date)){
    //this time frame has already passed
    return false;
  }
  const servingOptionStartTime = getStartTime(
    servingOptionTimeFrame,
    timeZoneStr,
    date,
  );
  const servingOptionEndTime = getEndTime(
    servingOptionTimeFrame,
    timeZoneStr,
    date,
  );

  if (
    openHoursStartTime.isSameOrBefore(servingOptionStartTime) &&
    openHoursEndTime.isSameOrAfter(servingOptionEndTime)
  ) {
    return true;
  }
  if (
    openHoursStartTime.isSameOrAfter(servingOptionStartTime) &&
    openHoursEndTime.isSameOrBefore(servingOptionEndTime)
  ) {
    return true;
  }
  if (
    openHoursStartTime.isSameOrBefore(servingOptionStartTime) &&
    openHoursEndTime.isAfter(servingOptionStartTime)
  ) {
    return true;
  }
  if (
    openHoursStartTime.isSameOrAfter(servingOptionStartTime) &&
    openHoursStartTime.isBefore(servingOptionEndTime)
  ) {
    return true;
  }
  return false;
};

const filterOpenTimeFrames = (timeFrames) =>
  _.filter(
    timeFrames,
    (timeFrame) =>
      timeFrame.startHour !== 0 ||
      timeFrame.endHour !== 0 ||
      timeFrame.startMinute !== 0 ||
      timeFrame.endMinute !== 0,
  );

// TODO: change this
const getNextAvailableTimeFrame = ({
  firstAvailableDate,
  openHoursTimeFrames,
  servingOptionTimeFrames,
  timeZoneStr,
}) => {
  if (_.isEmpty(openHoursTimeFrames)) {
    return null;
  }
  if (_.isEmpty(servingOptionTimeFrames)) {
    return openHoursTimeFrames[0];
  }

  let chosenServingOptionTimeFrame;

  const chosenOpenHourTimeFrame = _.find(
    openHoursTimeFrames,
    (openHoursTimeFrame) => {
      chosenServingOptionTimeFrame = _.find(
        servingOptionTimeFrames,
        (servingOptionTimeFrame) => {
          if (
            isTimeFrameMatch(
              openHoursTimeFrame,
              servingOptionTimeFrame,
              firstAvailableDate,
              timeZoneStr,
            )
          ) {
            return true;
          }
          return false;
        },
      );
      return !_.isEmpty(chosenServingOptionTimeFrame);
    },
  );

  if (_.isEmpty(chosenOpenHourTimeFrame)) {
    return null;
  }

  const nextAvailableTimeFrame = {};
  if (
    chosenOpenHourTimeFrame.startHour > chosenServingOptionTimeFrame.startHour
  ) {
    nextAvailableTimeFrame.startHour = chosenOpenHourTimeFrame.startHour;
    nextAvailableTimeFrame.startMinute = chosenOpenHourTimeFrame.startMinute;
  } else if (
    chosenOpenHourTimeFrame.startHour < chosenServingOptionTimeFrame.startHour
  ) {
    nextAvailableTimeFrame.startHour = chosenServingOptionTimeFrame.startHour;
    nextAvailableTimeFrame.startMinute =
      chosenServingOptionTimeFrame.startMinute;
  } else {
    if (
      chosenOpenHourTimeFrame.startMinute >=
      chosenServingOptionTimeFrame.startMinute
    ) {
      nextAvailableTimeFrame.startHour = chosenOpenHourTimeFrame.startHour;
      nextAvailableTimeFrame.startMinute = chosenOpenHourTimeFrame.startMinute;
    } else {
      nextAvailableTimeFrame.startHour = chosenServingOptionTimeFrame.startHour;
      nextAvailableTimeFrame.startMinute =
        chosenServingOptionTimeFrame.startMinute;
    }
  }
  return nextAvailableTimeFrame;
};

export const getAvailableFrom = ({
  firstAvailableDate,
  openHoursTimeFrames,
  servingOptionTimeFrames,
  timeZoneStr,
}) => {
  const sortedOpenHoursTimeFrames = _.sortBy(
    filterOpenTimeFrames(openHoursTimeFrames),
    ["startHour", "startMinute"],
  );
  const sortedServingOptionTimeFrames = _.sortBy(
    filterOpenTimeFrames(servingOptionTimeFrames),
    ["startHour", "startMinute"],
  );

  const nextAvailableTimeFrame = getNextAvailableTimeFrame({
    firstAvailableDate,
    openHoursTimeFrames: sortedOpenHoursTimeFrames,
    servingOptionTimeFrames: sortedServingOptionTimeFrames,
    timeZoneStr,
  });

  return nextAvailableTimeFrame
    ? moment
      .tz(firstAvailableDate, timeZoneStr)
      .hours(nextAvailableTimeFrame.startHour)
      .minute(nextAvailableTimeFrame.startMinute)
      .seconds(0)
      .toDate()
    : null;
};
